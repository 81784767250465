
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form, useForm } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";
import { hideModal } from "@/core/helpers/dom";
import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

export default defineComponent({
    name: "edit-analytic-account-modal",
    emit: ["refreshData"],
    props: {
        analyticAccount: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editAnalyticAccountModalRef = ref<null | HTMLElement>(null);

        let isUpdate = false as boolean;
        let structures = ref([]);
        let errors: any = ref();

        const analyticAccountDefault = {
            id            : null,
            code          : "",
            name          : "",
            structure_type: 1,
            structure_code: "",
            description   : "",
        }
        const analyticAccountData = ref<any>({});
        Object.assign(analyticAccountData.value, analyticAccountDefault);

        const validationSchema = Yup.object().shape({
            // code: Yup.string().required().label(translate("analyticAccountCodeLabel")),
            // name: Yup.string().required().label(translate("analyticAccountNameLabel")),
            // structure_type: Yup.number().required().label(translate("analyticAccountStructureTypeLabel")),
            // structure_code: Yup.string().required().label(translate("analyticAccountStructureCodeLabel")),
            // description: Yup.string().required().label(translate("analyticAccountDescriptionLabel")),
        });

        const submit = (values, actions) => {
            errors.value = null;

            disableSubmitButton(submitButtonRef);

            if (isUpdate && props.analyticAccount.id) analyticAccountData.value.id = props.analyticAccount.id;

            axios.post(ApiRoutes.analyticAccounts.updateOrCreate, analyticAccountData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton(submitButtonRef);

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editAnalyticAccountModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton(submitButtonRef);

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
        };

        const getStructures = async () => {
            const level = (analyticAccountData.value) ? analyticAccountData.value.structure_type : 1;
            const response = await axios.get(ApiRoutes.structures.list, { params: { level: level } });

            structures.value = response.data;
        };

        onMounted(() => {
            const modal = document.getElementById('kt_modal_edit_analytic_account');

            if (modal) modal.addEventListener('hidden.bs.modal', function() {
                Object.assign(analyticAccountData.value, analyticAccountDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;
                errors.value = null;

                if (props.analyticAccount) {
                    isUpdate = true;

                    // Fill analyticAccount fields
                    Object.keys(props.analyticAccount).forEach((key) => {
                        if (analyticAccountDefault.hasOwnProperty(key)) analyticAccountData.value[key] = props.analyticAccount[key];
                    });

                    switch (analyticAccountData.value.structure_code.length) {
                        case 6:
                            analyticAccountData.value.structure_type = 2;
                            break;

                        case 9:
                            analyticAccountData.value.structure_type = 3;
                            break;

                        case 12:
                            analyticAccountData.value.structure_type = 4;
                            break;

                        case 15:
                            analyticAccountData.value.structure_type = 5;
                            break;

                        default:
                            analyticAccountData.value.structure_type = 1;
                            break;
                    }

                    getStructures();

                } else {
                    getStructures();
                }
            });
        });

        return {
            translate,

            submitButtonRef,
            editAnalyticAccountModalRef,

            validationSchema,

            errors,
            resetField,
            checkField,

            structures,
            getStructures,
            analyticAccountData,

            submit,
        };
    },
});
